// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/fleetconsole/api/fleetconsolerpc/service.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Empty } from "../../../../../google/protobuf/empty.pb";
import { DateOnly } from "./common_types.pb";

export const protobufPackage = "fleetconsole";

export enum DeviceType {
  DEVICE_TYPE_UNSPECIFIED = 0,
  DEVICE_TYPE_VIRTUAL = 1,
  DEVICE_TYPE_PHYSICAL = 2,
}

export function deviceTypeFromJSON(object: any): DeviceType {
  switch (object) {
    case 0:
    case "DEVICE_TYPE_UNSPECIFIED":
      return DeviceType.DEVICE_TYPE_UNSPECIFIED;
    case 1:
    case "DEVICE_TYPE_VIRTUAL":
      return DeviceType.DEVICE_TYPE_VIRTUAL;
    case 2:
    case "DEVICE_TYPE_PHYSICAL":
      return DeviceType.DEVICE_TYPE_PHYSICAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceType");
  }
}

export function deviceTypeToJSON(object: DeviceType): string {
  switch (object) {
    case DeviceType.DEVICE_TYPE_UNSPECIFIED:
      return "DEVICE_TYPE_UNSPECIFIED";
    case DeviceType.DEVICE_TYPE_VIRTUAL:
      return "DEVICE_TYPE_VIRTUAL";
    case DeviceType.DEVICE_TYPE_PHYSICAL:
      return "DEVICE_TYPE_PHYSICAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceType");
  }
}

export enum DeviceState {
  DEVICE_STATE_UNSPECIFIED = 0,
  /**
   * DEVICE_STATE_AVAILABLE - Default state.
   * Device is available to be leased.
   */
  DEVICE_STATE_AVAILABLE = 1,
  /** DEVICE_STATE_LEASED - Device is leased. */
  DEVICE_STATE_LEASED = 2,
}

export function deviceStateFromJSON(object: any): DeviceState {
  switch (object) {
    case 0:
    case "DEVICE_STATE_UNSPECIFIED":
      return DeviceState.DEVICE_STATE_UNSPECIFIED;
    case 1:
    case "DEVICE_STATE_AVAILABLE":
      return DeviceState.DEVICE_STATE_AVAILABLE;
    case 2:
    case "DEVICE_STATE_LEASED":
      return DeviceState.DEVICE_STATE_LEASED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceState");
  }
}

export function deviceStateToJSON(object: DeviceState): string {
  switch (object) {
    case DeviceState.DEVICE_STATE_UNSPECIFIED:
      return "DEVICE_STATE_UNSPECIFIED";
    case DeviceState.DEVICE_STATE_AVAILABLE:
      return "DEVICE_STATE_AVAILABLE";
    case DeviceState.DEVICE_STATE_LEASED:
      return "DEVICE_STATE_LEASED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceState");
  }
}

/** PingRequest intentionally contains nothing. */
export interface PingRequest {
}

/** PingResponse intentionally contains nothing. */
export interface PingResponse {
}

/** PingBigQueryRequest intentionally contains nothing. */
export interface PingBigQueryRequest {
}

/** PingBigQueryResponse intentionally contains nothing. */
export interface PingBigQueryResponse {
}

/** PingDeviceManagerRequest intentionally contains nothing. */
export interface PingDeviceManagerRequest {
}

/** PingDeviceManagerResponse intentionally contains nothing. */
export interface PingDeviceManagerResponse {
}

/** PingUfsRequest intentionally contains nothing. */
export interface PingUfsRequest {
}

/** PingUfsResponse intentionally contains nothing. */
export interface PingUfsResponse {
}

export interface Device {
  /**
   * In the case of VMs, device id could be the GCE instance name. For physical
   * DUTs we use the IDs we get from UFS.
   */
  readonly id: string;
  /** dut_id is the asset tag for a Device */
  readonly dutId: string;
  readonly address: DeviceAddress | undefined;
  readonly type: DeviceType;
  readonly state: DeviceState;
  /** // This proto contains per-DUT hardware relevant information including labels. */
  readonly deviceSpec: DeviceSpec | undefined;
}

export interface DeviceAddress {
  /**
   * IP address of the device.
   * For physical DUTs it can be a hostname.
   */
  readonly host: string;
  readonly port: number;
}

export interface ListDevicesRequest {
  /**
   * The maximum number of devices to return. The service may return fewer than
   * this value. If unspecified, at most 1000 devices will be returned.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListDevices` call. Provide this to
   * retrieve the subsequent page. Returns first page if omitted.
   *
   * When paginating, all other parameters provided to `ListDevices` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  readonly orderBy: string;
  /** See: AIP-160 (https://google.aip.dev/160) for the syntax */
  readonly filter: string;
}

export interface ListDevicesResponse {
  /** List of devices to be returned. */
  readonly devices: readonly Device[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page. If
   * this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeviceSpec {
  /** A map for labels used to request a matching device. */
  readonly labels: { [key: string]: LabelValues };
}

export interface DeviceSpec_LabelsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface GetDeviceDimensionsResponse {
  readonly baseDimensions: { [key: string]: LabelValues };
  readonly labels: { [key: string]: LabelValues };
}

export interface GetDeviceDimensionsResponse_BaseDimensionsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface GetDeviceDimensionsResponse_LabelsEntry {
  readonly key: string;
  readonly value: LabelValues | undefined;
}

export interface LabelValues {
  readonly values: readonly string[];
}

export interface CountDevicesRequest {
  /**
   * See: AIP-160 (https://google.aip.dev/160) for the syntax
   * Same filter format as ListDevicesRequest
   */
  readonly filter: string;
}

export interface CountDevicesResponse {
  readonly total: number;
  readonly taskState: TaskStateCounts | undefined;
  readonly deviceState: DeviceStateCounts | undefined;
}

export interface TaskStateCounts {
  readonly busy: number;
  readonly idle: number;
}

export interface DeviceStateCounts {
  readonly ready: number;
  readonly needManualRepair: number;
  readonly needRepair: number;
  readonly repairFailed: number;
}

export interface RepopulateCacheRequest {
}

export interface RepopulateCacheResponse {
}

export interface PingDBRequest {
}

export interface PingDBResponse {
}

export interface CleanExitRequest {
}

export interface CleanExitResponse {
}

export interface ExportDevicesToCSVRequest {
  readonly columns: readonly Column[];
  readonly orderBy: string;
  /** See: AIP-160 (https://google.aip.dev/160) for the syntax */
  readonly filter: string;
  /** If specified will export only the devices with the specified ids. */
  readonly ids: readonly string[];
}

export interface Column {
  readonly name: string;
  readonly displayName: string;
}

export interface ExportDevicesToCSVResponse {
  readonly csvData: string;
}

export interface ListResourceRequestsRequest {
  /**
   * The maximum number of Resource Requests to return. The service may return fewer than
   * this value. If unspecified, at most 50 Resource Requests will be returned.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListResourceRequests` call. Provide this to
   * retrieve the subsequent page. Returns first page if omitted.
   *
   * When paginating, all other parameters provided to `ListResourceRequests` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  readonly orderBy: string;
  /** See: AIP-160 (https://google.aip.dev/160) for the syntax */
  readonly filter: string;
}

export interface ListResourceRequestsResponse {
  /** List of Resource Requests to be returned. */
  readonly resourceRequests: readonly ResourceRequest[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page. If
   * this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface ResourceRequest {
  /**
   * The resource name of the resource request.
   * Format: resourceRequests/{rr_id}
   */
  readonly name: string;
  /** The ID of the resource request. */
  readonly rrId: string;
  /** The details of the resource request. */
  readonly resourceDetails: string;
  /** The procurement end date of the resource request. */
  readonly procurementEndDate?:
    | DateOnly
    | undefined;
  /** The build end date of the resource request. */
  readonly buildEndDate?:
    | DateOnly
    | undefined;
  /** The QA end date of the resource request. */
  readonly qaEndDate?:
    | DateOnly
    | undefined;
  /** The config end date of the resource request. */
  readonly configEndDate?:
    | DateOnly
    | undefined;
  /** Expected ETA for the resource request. */
  readonly expectedEta?:
    | DateOnly
    | undefined;
  /** Status of the Fulfillment bug for the resource request. */
  readonly fulfillmentStatus?: ResourceRequest_Status | undefined;
}

export enum ResourceRequest_Status {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export function resourceRequest_StatusFromJSON(object: any): ResourceRequest_Status {
  switch (object) {
    case 0:
    case "NOT_STARTED":
      return ResourceRequest_Status.NOT_STARTED;
    case 1:
    case "IN_PROGRESS":
      return ResourceRequest_Status.IN_PROGRESS;
    case 2:
    case "COMPLETED":
      return ResourceRequest_Status.COMPLETED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceRequest_Status");
  }
}

export function resourceRequest_StatusToJSON(object: ResourceRequest_Status): string {
  switch (object) {
    case ResourceRequest_Status.NOT_STARTED:
      return "NOT_STARTED";
    case ResourceRequest_Status.IN_PROGRESS:
      return "IN_PROGRESS";
    case ResourceRequest_Status.COMPLETED:
      return "COMPLETED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceRequest_Status");
  }
}

function createBasePingRequest(): PingRequest {
  return {};
}

export const PingRequest: MessageFns<PingRequest> = {
  encode(_: PingRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingRequest {
    return {};
  },

  toJSON(_: PingRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingRequest>): PingRequest {
    return PingRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingRequest>): PingRequest {
    const message = createBasePingRequest() as any;
    return message;
  },
};

function createBasePingResponse(): PingResponse {
  return {};
}

export const PingResponse: MessageFns<PingResponse> = {
  encode(_: PingResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingResponse {
    return {};
  },

  toJSON(_: PingResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingResponse>): PingResponse {
    return PingResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingResponse>): PingResponse {
    const message = createBasePingResponse() as any;
    return message;
  },
};

function createBasePingBigQueryRequest(): PingBigQueryRequest {
  return {};
}

export const PingBigQueryRequest: MessageFns<PingBigQueryRequest> = {
  encode(_: PingBigQueryRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingBigQueryRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingBigQueryRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingBigQueryRequest {
    return {};
  },

  toJSON(_: PingBigQueryRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingBigQueryRequest>): PingBigQueryRequest {
    return PingBigQueryRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingBigQueryRequest>): PingBigQueryRequest {
    const message = createBasePingBigQueryRequest() as any;
    return message;
  },
};

function createBasePingBigQueryResponse(): PingBigQueryResponse {
  return {};
}

export const PingBigQueryResponse: MessageFns<PingBigQueryResponse> = {
  encode(_: PingBigQueryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingBigQueryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingBigQueryResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingBigQueryResponse {
    return {};
  },

  toJSON(_: PingBigQueryResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingBigQueryResponse>): PingBigQueryResponse {
    return PingBigQueryResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingBigQueryResponse>): PingBigQueryResponse {
    const message = createBasePingBigQueryResponse() as any;
    return message;
  },
};

function createBasePingDeviceManagerRequest(): PingDeviceManagerRequest {
  return {};
}

export const PingDeviceManagerRequest: MessageFns<PingDeviceManagerRequest> = {
  encode(_: PingDeviceManagerRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDeviceManagerRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDeviceManagerRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDeviceManagerRequest {
    return {};
  },

  toJSON(_: PingDeviceManagerRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDeviceManagerRequest>): PingDeviceManagerRequest {
    return PingDeviceManagerRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDeviceManagerRequest>): PingDeviceManagerRequest {
    const message = createBasePingDeviceManagerRequest() as any;
    return message;
  },
};

function createBasePingDeviceManagerResponse(): PingDeviceManagerResponse {
  return {};
}

export const PingDeviceManagerResponse: MessageFns<PingDeviceManagerResponse> = {
  encode(_: PingDeviceManagerResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDeviceManagerResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDeviceManagerResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDeviceManagerResponse {
    return {};
  },

  toJSON(_: PingDeviceManagerResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDeviceManagerResponse>): PingDeviceManagerResponse {
    return PingDeviceManagerResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDeviceManagerResponse>): PingDeviceManagerResponse {
    const message = createBasePingDeviceManagerResponse() as any;
    return message;
  },
};

function createBasePingUfsRequest(): PingUfsRequest {
  return {};
}

export const PingUfsRequest: MessageFns<PingUfsRequest> = {
  encode(_: PingUfsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingUfsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingUfsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingUfsRequest {
    return {};
  },

  toJSON(_: PingUfsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingUfsRequest>): PingUfsRequest {
    return PingUfsRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingUfsRequest>): PingUfsRequest {
    const message = createBasePingUfsRequest() as any;
    return message;
  },
};

function createBasePingUfsResponse(): PingUfsResponse {
  return {};
}

export const PingUfsResponse: MessageFns<PingUfsResponse> = {
  encode(_: PingUfsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingUfsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingUfsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingUfsResponse {
    return {};
  },

  toJSON(_: PingUfsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingUfsResponse>): PingUfsResponse {
    return PingUfsResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingUfsResponse>): PingUfsResponse {
    const message = createBasePingUfsResponse() as any;
    return message;
  },
};

function createBaseDevice(): Device {
  return { id: "", dutId: "", address: undefined, type: 0, state: 0, deviceSpec: undefined };
}

export const Device: MessageFns<Device> = {
  encode(message: Device, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.dutId !== "") {
      writer.uint32(50).string(message.dutId);
    }
    if (message.address !== undefined) {
      DeviceAddress.encode(message.address, writer.uint32(18).fork()).join();
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.state !== 0) {
      writer.uint32(32).int32(message.state);
    }
    if (message.deviceSpec !== undefined) {
      DeviceSpec.encode(message.deviceSpec, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Device {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.dutId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.address = DeviceAddress.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.deviceSpec = DeviceSpec.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      dutId: isSet(object.dutId) ? globalThis.String(object.dutId) : "",
      address: isSet(object.address) ? DeviceAddress.fromJSON(object.address) : undefined,
      type: isSet(object.type) ? deviceTypeFromJSON(object.type) : 0,
      state: isSet(object.state) ? deviceStateFromJSON(object.state) : 0,
      deviceSpec: isSet(object.deviceSpec) ? DeviceSpec.fromJSON(object.deviceSpec) : undefined,
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.dutId !== "") {
      obj.dutId = message.dutId;
    }
    if (message.address !== undefined) {
      obj.address = DeviceAddress.toJSON(message.address);
    }
    if (message.type !== 0) {
      obj.type = deviceTypeToJSON(message.type);
    }
    if (message.state !== 0) {
      obj.state = deviceStateToJSON(message.state);
    }
    if (message.deviceSpec !== undefined) {
      obj.deviceSpec = DeviceSpec.toJSON(message.deviceSpec);
    }
    return obj;
  },

  create(base?: DeepPartial<Device>): Device {
    return Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device>): Device {
    const message = createBaseDevice() as any;
    message.id = object.id ?? "";
    message.dutId = object.dutId ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? DeviceAddress.fromPartial(object.address)
      : undefined;
    message.type = object.type ?? 0;
    message.state = object.state ?? 0;
    message.deviceSpec = (object.deviceSpec !== undefined && object.deviceSpec !== null)
      ? DeviceSpec.fromPartial(object.deviceSpec)
      : undefined;
    return message;
  },
};

function createBaseDeviceAddress(): DeviceAddress {
  return { host: "", port: 0 };
}

export const DeviceAddress: MessageFns<DeviceAddress> = {
  encode(message: DeviceAddress, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== "") {
      writer.uint32(10).string(message.host);
    }
    if (message.port !== 0) {
      writer.uint32(16).int32(message.port);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceAddress {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAddress() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.host = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.port = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAddress {
    return {
      host: isSet(object.host) ? globalThis.String(object.host) : "",
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
    };
  },

  toJSON(message: DeviceAddress): unknown {
    const obj: any = {};
    if (message.host !== "") {
      obj.host = message.host;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceAddress>): DeviceAddress {
    return DeviceAddress.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceAddress>): DeviceAddress {
    const message = createBaseDeviceAddress() as any;
    message.host = object.host ?? "";
    message.port = object.port ?? 0;
    return message;
  },
};

function createBaseListDevicesRequest(): ListDevicesRequest {
  return { pageSize: 0, pageToken: "", orderBy: "", filter: "" };
}

export const ListDevicesRequest: MessageFns<ListDevicesRequest> = {
  encode(message: ListDevicesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.orderBy !== "") {
      writer.uint32(26).string(message.orderBy);
    }
    if (message.filter !== "") {
      writer.uint32(34).string(message.filter);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDevicesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevicesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDevicesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
    };
  },

  toJSON(message: ListDevicesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDevicesRequest>): ListDevicesRequest {
    return ListDevicesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevicesRequest>): ListDevicesRequest {
    const message = createBaseListDevicesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.orderBy = object.orderBy ?? "";
    message.filter = object.filter ?? "";
    return message;
  },
};

function createBaseListDevicesResponse(): ListDevicesResponse {
  return { devices: [], nextPageToken: "" };
}

export const ListDevicesResponse: MessageFns<ListDevicesResponse> = {
  encode(message: ListDevicesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDevicesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevicesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDevicesResponse {
    return {
      devices: globalThis.Array.isArray(object?.devices) ? object.devices.map((e: any) => Device.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListDevicesResponse): unknown {
    const obj: any = {};
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => Device.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDevicesResponse>): ListDevicesResponse {
    return ListDevicesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevicesResponse>): ListDevicesResponse {
    const message = createBaseListDevicesResponse() as any;
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeviceSpec(): DeviceSpec {
  return { labels: {} };
}

export const DeviceSpec: MessageFns<DeviceSpec> = {
  encode(message: DeviceSpec, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.labels).forEach(([key, value]) => {
      DeviceSpec_LabelsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceSpec {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceSpec() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          const entry1 = DeviceSpec_LabelsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.labels[entry1.key] = entry1.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceSpec {
    return {
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DeviceSpec): unknown {
    const obj: any = {};
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = LabelValues.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceSpec>): DeviceSpec {
    return DeviceSpec.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceSpec>): DeviceSpec {
    const message = createBaseDeviceSpec() as any;
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = LabelValues.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDeviceSpec_LabelsEntry(): DeviceSpec_LabelsEntry {
  return { key: "", value: undefined };
}

export const DeviceSpec_LabelsEntry: MessageFns<DeviceSpec_LabelsEntry> = {
  encode(message: DeviceSpec_LabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceSpec_LabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceSpec_LabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceSpec_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DeviceSpec_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceSpec_LabelsEntry>): DeviceSpec_LabelsEntry {
    return DeviceSpec_LabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceSpec_LabelsEntry>): DeviceSpec_LabelsEntry {
    const message = createBaseDeviceSpec_LabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse(): GetDeviceDimensionsResponse {
  return { baseDimensions: {}, labels: {} };
}

export const GetDeviceDimensionsResponse: MessageFns<GetDeviceDimensionsResponse> = {
  encode(message: GetDeviceDimensionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.baseDimensions).forEach(([key, value]) => {
      GetDeviceDimensionsResponse_BaseDimensionsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .join();
    });
    Object.entries(message.labels).forEach(([key, value]) => {
      GetDeviceDimensionsResponse_LabelsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          const entry1 = GetDeviceDimensionsResponse_BaseDimensionsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.baseDimensions[entry1.key] = entry1.value;
          }
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          const entry2 = GetDeviceDimensionsResponse_LabelsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.labels[entry2.key] = entry2.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse {
    return {
      baseDimensions: isObject(object.baseDimensions)
        ? Object.entries(object.baseDimensions).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
          acc[key] = LabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: GetDeviceDimensionsResponse): unknown {
    const obj: any = {};
    if (message.baseDimensions) {
      const entries = Object.entries(message.baseDimensions);
      if (entries.length > 0) {
        obj.baseDimensions = {};
        entries.forEach(([k, v]) => {
          obj.baseDimensions[k] = LabelValues.toJSON(v);
        });
      }
    }
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = LabelValues.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDimensionsResponse>): GetDeviceDimensionsResponse {
    return GetDeviceDimensionsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDimensionsResponse>): GetDeviceDimensionsResponse {
    const message = createBaseGetDeviceDimensionsResponse() as any;
    message.baseDimensions = Object.entries(object.baseDimensions ?? {}).reduce<{ [key: string]: LabelValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = LabelValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: LabelValues }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = LabelValues.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry(): GetDeviceDimensionsResponse_BaseDimensionsEntry {
  return { key: "", value: undefined };
}

export const GetDeviceDimensionsResponse_BaseDimensionsEntry: MessageFns<
  GetDeviceDimensionsResponse_BaseDimensionsEntry
> = {
  encode(
    message: GetDeviceDimensionsResponse_BaseDimensionsEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetDeviceDimensionsResponse_BaseDimensionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetDeviceDimensionsResponse_BaseDimensionsEntry>,
  ): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    return GetDeviceDimensionsResponse_BaseDimensionsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<GetDeviceDimensionsResponse_BaseDimensionsEntry>,
  ): GetDeviceDimensionsResponse_BaseDimensionsEntry {
    const message = createBaseGetDeviceDimensionsResponse_BaseDimensionsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetDeviceDimensionsResponse_LabelsEntry(): GetDeviceDimensionsResponse_LabelsEntry {
  return { key: "", value: undefined };
}

export const GetDeviceDimensionsResponse_LabelsEntry: MessageFns<GetDeviceDimensionsResponse_LabelsEntry> = {
  encode(message: GetDeviceDimensionsResponse_LabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      LabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDimensionsResponse_LabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDimensionsResponse_LabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = LabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDimensionsResponse_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? LabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetDeviceDimensionsResponse_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = LabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDimensionsResponse_LabelsEntry>): GetDeviceDimensionsResponse_LabelsEntry {
    return GetDeviceDimensionsResponse_LabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDimensionsResponse_LabelsEntry>): GetDeviceDimensionsResponse_LabelsEntry {
    const message = createBaseGetDeviceDimensionsResponse_LabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? LabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseLabelValues(): LabelValues {
  return { values: [] };
}

export const LabelValues: MessageFns<LabelValues> = {
  encode(message: LabelValues, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.values) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LabelValues {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabelValues() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.values.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LabelValues {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: LabelValues): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },

  create(base?: DeepPartial<LabelValues>): LabelValues {
    return LabelValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LabelValues>): LabelValues {
    const message = createBaseLabelValues() as any;
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseCountDevicesRequest(): CountDevicesRequest {
  return { filter: "" };
}

export const CountDevicesRequest: MessageFns<CountDevicesRequest> = {
  encode(message: CountDevicesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.filter !== "") {
      writer.uint32(10).string(message.filter);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CountDevicesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountDevicesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountDevicesRequest {
    return { filter: isSet(object.filter) ? globalThis.String(object.filter) : "" };
  },

  toJSON(message: CountDevicesRequest): unknown {
    const obj: any = {};
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    return obj;
  },

  create(base?: DeepPartial<CountDevicesRequest>): CountDevicesRequest {
    return CountDevicesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CountDevicesRequest>): CountDevicesRequest {
    const message = createBaseCountDevicesRequest() as any;
    message.filter = object.filter ?? "";
    return message;
  },
};

function createBaseCountDevicesResponse(): CountDevicesResponse {
  return { total: 0, taskState: undefined, deviceState: undefined };
}

export const CountDevicesResponse: MessageFns<CountDevicesResponse> = {
  encode(message: CountDevicesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.total !== 0) {
      writer.uint32(8).int32(message.total);
    }
    if (message.taskState !== undefined) {
      TaskStateCounts.encode(message.taskState, writer.uint32(18).fork()).join();
    }
    if (message.deviceState !== undefined) {
      DeviceStateCounts.encode(message.deviceState, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CountDevicesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountDevicesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.total = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.taskState = TaskStateCounts.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.deviceState = DeviceStateCounts.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CountDevicesResponse {
    return {
      total: isSet(object.total) ? globalThis.Number(object.total) : 0,
      taskState: isSet(object.taskState) ? TaskStateCounts.fromJSON(object.taskState) : undefined,
      deviceState: isSet(object.deviceState) ? DeviceStateCounts.fromJSON(object.deviceState) : undefined,
    };
  },

  toJSON(message: CountDevicesResponse): unknown {
    const obj: any = {};
    if (message.total !== 0) {
      obj.total = Math.round(message.total);
    }
    if (message.taskState !== undefined) {
      obj.taskState = TaskStateCounts.toJSON(message.taskState);
    }
    if (message.deviceState !== undefined) {
      obj.deviceState = DeviceStateCounts.toJSON(message.deviceState);
    }
    return obj;
  },

  create(base?: DeepPartial<CountDevicesResponse>): CountDevicesResponse {
    return CountDevicesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CountDevicesResponse>): CountDevicesResponse {
    const message = createBaseCountDevicesResponse() as any;
    message.total = object.total ?? 0;
    message.taskState = (object.taskState !== undefined && object.taskState !== null)
      ? TaskStateCounts.fromPartial(object.taskState)
      : undefined;
    message.deviceState = (object.deviceState !== undefined && object.deviceState !== null)
      ? DeviceStateCounts.fromPartial(object.deviceState)
      : undefined;
    return message;
  },
};

function createBaseTaskStateCounts(): TaskStateCounts {
  return { busy: 0, idle: 0 };
}

export const TaskStateCounts: MessageFns<TaskStateCounts> = {
  encode(message: TaskStateCounts, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.busy !== 0) {
      writer.uint32(8).int32(message.busy);
    }
    if (message.idle !== 0) {
      writer.uint32(16).int32(message.idle);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TaskStateCounts {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskStateCounts() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.busy = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.idle = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskStateCounts {
    return {
      busy: isSet(object.busy) ? globalThis.Number(object.busy) : 0,
      idle: isSet(object.idle) ? globalThis.Number(object.idle) : 0,
    };
  },

  toJSON(message: TaskStateCounts): unknown {
    const obj: any = {};
    if (message.busy !== 0) {
      obj.busy = Math.round(message.busy);
    }
    if (message.idle !== 0) {
      obj.idle = Math.round(message.idle);
    }
    return obj;
  },

  create(base?: DeepPartial<TaskStateCounts>): TaskStateCounts {
    return TaskStateCounts.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TaskStateCounts>): TaskStateCounts {
    const message = createBaseTaskStateCounts() as any;
    message.busy = object.busy ?? 0;
    message.idle = object.idle ?? 0;
    return message;
  },
};

function createBaseDeviceStateCounts(): DeviceStateCounts {
  return { ready: 0, needManualRepair: 0, needRepair: 0, repairFailed: 0 };
}

export const DeviceStateCounts: MessageFns<DeviceStateCounts> = {
  encode(message: DeviceStateCounts, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ready !== 0) {
      writer.uint32(8).int32(message.ready);
    }
    if (message.needManualRepair !== 0) {
      writer.uint32(16).int32(message.needManualRepair);
    }
    if (message.needRepair !== 0) {
      writer.uint32(24).int32(message.needRepair);
    }
    if (message.repairFailed !== 0) {
      writer.uint32(32).int32(message.repairFailed);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceStateCounts {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceStateCounts() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.ready = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.needManualRepair = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.needRepair = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.repairFailed = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceStateCounts {
    return {
      ready: isSet(object.ready) ? globalThis.Number(object.ready) : 0,
      needManualRepair: isSet(object.needManualRepair) ? globalThis.Number(object.needManualRepair) : 0,
      needRepair: isSet(object.needRepair) ? globalThis.Number(object.needRepair) : 0,
      repairFailed: isSet(object.repairFailed) ? globalThis.Number(object.repairFailed) : 0,
    };
  },

  toJSON(message: DeviceStateCounts): unknown {
    const obj: any = {};
    if (message.ready !== 0) {
      obj.ready = Math.round(message.ready);
    }
    if (message.needManualRepair !== 0) {
      obj.needManualRepair = Math.round(message.needManualRepair);
    }
    if (message.needRepair !== 0) {
      obj.needRepair = Math.round(message.needRepair);
    }
    if (message.repairFailed !== 0) {
      obj.repairFailed = Math.round(message.repairFailed);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceStateCounts>): DeviceStateCounts {
    return DeviceStateCounts.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceStateCounts>): DeviceStateCounts {
    const message = createBaseDeviceStateCounts() as any;
    message.ready = object.ready ?? 0;
    message.needManualRepair = object.needManualRepair ?? 0;
    message.needRepair = object.needRepair ?? 0;
    message.repairFailed = object.repairFailed ?? 0;
    return message;
  },
};

function createBaseRepopulateCacheRequest(): RepopulateCacheRequest {
  return {};
}

export const RepopulateCacheRequest: MessageFns<RepopulateCacheRequest> = {
  encode(_: RepopulateCacheRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RepopulateCacheRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepopulateCacheRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RepopulateCacheRequest {
    return {};
  },

  toJSON(_: RepopulateCacheRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<RepopulateCacheRequest>): RepopulateCacheRequest {
    return RepopulateCacheRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RepopulateCacheRequest>): RepopulateCacheRequest {
    const message = createBaseRepopulateCacheRequest() as any;
    return message;
  },
};

function createBaseRepopulateCacheResponse(): RepopulateCacheResponse {
  return {};
}

export const RepopulateCacheResponse: MessageFns<RepopulateCacheResponse> = {
  encode(_: RepopulateCacheResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RepopulateCacheResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRepopulateCacheResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RepopulateCacheResponse {
    return {};
  },

  toJSON(_: RepopulateCacheResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<RepopulateCacheResponse>): RepopulateCacheResponse {
    return RepopulateCacheResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RepopulateCacheResponse>): RepopulateCacheResponse {
    const message = createBaseRepopulateCacheResponse() as any;
    return message;
  },
};

function createBasePingDBRequest(): PingDBRequest {
  return {};
}

export const PingDBRequest: MessageFns<PingDBRequest> = {
  encode(_: PingDBRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDBRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDBRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDBRequest {
    return {};
  },

  toJSON(_: PingDBRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDBRequest>): PingDBRequest {
    return PingDBRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDBRequest>): PingDBRequest {
    const message = createBasePingDBRequest() as any;
    return message;
  },
};

function createBasePingDBResponse(): PingDBResponse {
  return {};
}

export const PingDBResponse: MessageFns<PingDBResponse> = {
  encode(_: PingDBResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PingDBResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingDBResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PingDBResponse {
    return {};
  },

  toJSON(_: PingDBResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PingDBResponse>): PingDBResponse {
    return PingDBResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<PingDBResponse>): PingDBResponse {
    const message = createBasePingDBResponse() as any;
    return message;
  },
};

function createBaseCleanExitRequest(): CleanExitRequest {
  return {};
}

export const CleanExitRequest: MessageFns<CleanExitRequest> = {
  encode(_: CleanExitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CleanExitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCleanExitRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CleanExitRequest {
    return {};
  },

  toJSON(_: CleanExitRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<CleanExitRequest>): CleanExitRequest {
    return CleanExitRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CleanExitRequest>): CleanExitRequest {
    const message = createBaseCleanExitRequest() as any;
    return message;
  },
};

function createBaseCleanExitResponse(): CleanExitResponse {
  return {};
}

export const CleanExitResponse: MessageFns<CleanExitResponse> = {
  encode(_: CleanExitResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CleanExitResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCleanExitResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CleanExitResponse {
    return {};
  },

  toJSON(_: CleanExitResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<CleanExitResponse>): CleanExitResponse {
    return CleanExitResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CleanExitResponse>): CleanExitResponse {
    const message = createBaseCleanExitResponse() as any;
    return message;
  },
};

function createBaseExportDevicesToCSVRequest(): ExportDevicesToCSVRequest {
  return { columns: [], orderBy: "", filter: "", ids: [] };
}

export const ExportDevicesToCSVRequest: MessageFns<ExportDevicesToCSVRequest> = {
  encode(message: ExportDevicesToCSVRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.orderBy !== "") {
      writer.uint32(18).string(message.orderBy);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    for (const v of message.ids) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExportDevicesToCSVRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportDevicesToCSVRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportDevicesToCSVRequest {
    return {
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ExportDevicesToCSVRequest): unknown {
    const obj: any = {};
    if (message.columns?.length) {
      obj.columns = message.columns.map((e) => Column.toJSON(e));
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    return obj;
  },

  create(base?: DeepPartial<ExportDevicesToCSVRequest>): ExportDevicesToCSVRequest {
    return ExportDevicesToCSVRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExportDevicesToCSVRequest>): ExportDevicesToCSVRequest {
    const message = createBaseExportDevicesToCSVRequest() as any;
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.orderBy = object.orderBy ?? "";
    message.filter = object.filter ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseColumn(): Column {
  return { name: "", displayName: "" };
}

export const Column: MessageFns<Column> = {
  encode(message: Column, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Column {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseColumn() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Column {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
    };
  },

  toJSON(message: Column): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    return obj;
  },

  create(base?: DeepPartial<Column>): Column {
    return Column.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Column>): Column {
    const message = createBaseColumn() as any;
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    return message;
  },
};

function createBaseExportDevicesToCSVResponse(): ExportDevicesToCSVResponse {
  return { csvData: "" };
}

export const ExportDevicesToCSVResponse: MessageFns<ExportDevicesToCSVResponse> = {
  encode(message: ExportDevicesToCSVResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.csvData !== "") {
      writer.uint32(10).string(message.csvData);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ExportDevicesToCSVResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportDevicesToCSVResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.csvData = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportDevicesToCSVResponse {
    return { csvData: isSet(object.csvData) ? globalThis.String(object.csvData) : "" };
  },

  toJSON(message: ExportDevicesToCSVResponse): unknown {
    const obj: any = {};
    if (message.csvData !== "") {
      obj.csvData = message.csvData;
    }
    return obj;
  },

  create(base?: DeepPartial<ExportDevicesToCSVResponse>): ExportDevicesToCSVResponse {
    return ExportDevicesToCSVResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExportDevicesToCSVResponse>): ExportDevicesToCSVResponse {
    const message = createBaseExportDevicesToCSVResponse() as any;
    message.csvData = object.csvData ?? "";
    return message;
  },
};

function createBaseListResourceRequestsRequest(): ListResourceRequestsRequest {
  return { pageSize: 0, pageToken: "", orderBy: "", filter: "" };
}

export const ListResourceRequestsRequest: MessageFns<ListResourceRequestsRequest> = {
  encode(message: ListResourceRequestsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.orderBy !== "") {
      writer.uint32(26).string(message.orderBy);
    }
    if (message.filter !== "") {
      writer.uint32(34).string(message.filter);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListResourceRequestsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListResourceRequestsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.orderBy = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListResourceRequestsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      orderBy: isSet(object.orderBy) ? globalThis.String(object.orderBy) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
    };
  },

  toJSON(message: ListResourceRequestsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.orderBy !== "") {
      obj.orderBy = message.orderBy;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    return obj;
  },

  create(base?: DeepPartial<ListResourceRequestsRequest>): ListResourceRequestsRequest {
    return ListResourceRequestsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListResourceRequestsRequest>): ListResourceRequestsRequest {
    const message = createBaseListResourceRequestsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.orderBy = object.orderBy ?? "";
    message.filter = object.filter ?? "";
    return message;
  },
};

function createBaseListResourceRequestsResponse(): ListResourceRequestsResponse {
  return { resourceRequests: [], nextPageToken: "" };
}

export const ListResourceRequestsResponse: MessageFns<ListResourceRequestsResponse> = {
  encode(message: ListResourceRequestsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.resourceRequests) {
      ResourceRequest.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListResourceRequestsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListResourceRequestsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.resourceRequests.push(ResourceRequest.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListResourceRequestsResponse {
    return {
      resourceRequests: globalThis.Array.isArray(object?.resourceRequests)
        ? object.resourceRequests.map((e: any) => ResourceRequest.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListResourceRequestsResponse): unknown {
    const obj: any = {};
    if (message.resourceRequests?.length) {
      obj.resourceRequests = message.resourceRequests.map((e) => ResourceRequest.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListResourceRequestsResponse>): ListResourceRequestsResponse {
    return ListResourceRequestsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListResourceRequestsResponse>): ListResourceRequestsResponse {
    const message = createBaseListResourceRequestsResponse() as any;
    message.resourceRequests = object.resourceRequests?.map((e) => ResourceRequest.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseResourceRequest(): ResourceRequest {
  return {
    name: "",
    rrId: "",
    resourceDetails: "",
    procurementEndDate: undefined,
    buildEndDate: undefined,
    qaEndDate: undefined,
    configEndDate: undefined,
    expectedEta: undefined,
    fulfillmentStatus: undefined,
  };
}

export const ResourceRequest: MessageFns<ResourceRequest> = {
  encode(message: ResourceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.rrId !== "") {
      writer.uint32(18).string(message.rrId);
    }
    if (message.resourceDetails !== "") {
      writer.uint32(26).string(message.resourceDetails);
    }
    if (message.procurementEndDate !== undefined) {
      DateOnly.encode(message.procurementEndDate, writer.uint32(34).fork()).join();
    }
    if (message.buildEndDate !== undefined) {
      DateOnly.encode(message.buildEndDate, writer.uint32(42).fork()).join();
    }
    if (message.qaEndDate !== undefined) {
      DateOnly.encode(message.qaEndDate, writer.uint32(50).fork()).join();
    }
    if (message.configEndDate !== undefined) {
      DateOnly.encode(message.configEndDate, writer.uint32(58).fork()).join();
    }
    if (message.expectedEta !== undefined) {
      DateOnly.encode(message.expectedEta, writer.uint32(66).fork()).join();
    }
    if (message.fulfillmentStatus !== undefined) {
      writer.uint32(72).int32(message.fulfillmentStatus);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ResourceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResourceRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rrId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.resourceDetails = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.procurementEndDate = DateOnly.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.buildEndDate = DateOnly.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.qaEndDate = DateOnly.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.configEndDate = DateOnly.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.expectedEta = DateOnly.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.fulfillmentStatus = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResourceRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      rrId: isSet(object.rrId) ? globalThis.String(object.rrId) : "",
      resourceDetails: isSet(object.resourceDetails) ? globalThis.String(object.resourceDetails) : "",
      procurementEndDate: isSet(object.procurementEndDate) ? DateOnly.fromJSON(object.procurementEndDate) : undefined,
      buildEndDate: isSet(object.buildEndDate) ? DateOnly.fromJSON(object.buildEndDate) : undefined,
      qaEndDate: isSet(object.qaEndDate) ? DateOnly.fromJSON(object.qaEndDate) : undefined,
      configEndDate: isSet(object.configEndDate) ? DateOnly.fromJSON(object.configEndDate) : undefined,
      expectedEta: isSet(object.expectedEta) ? DateOnly.fromJSON(object.expectedEta) : undefined,
      fulfillmentStatus: isSet(object.fulfillmentStatus)
        ? resourceRequest_StatusFromJSON(object.fulfillmentStatus)
        : undefined,
    };
  },

  toJSON(message: ResourceRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.rrId !== "") {
      obj.rrId = message.rrId;
    }
    if (message.resourceDetails !== "") {
      obj.resourceDetails = message.resourceDetails;
    }
    if (message.procurementEndDate !== undefined) {
      obj.procurementEndDate = DateOnly.toJSON(message.procurementEndDate);
    }
    if (message.buildEndDate !== undefined) {
      obj.buildEndDate = DateOnly.toJSON(message.buildEndDate);
    }
    if (message.qaEndDate !== undefined) {
      obj.qaEndDate = DateOnly.toJSON(message.qaEndDate);
    }
    if (message.configEndDate !== undefined) {
      obj.configEndDate = DateOnly.toJSON(message.configEndDate);
    }
    if (message.expectedEta !== undefined) {
      obj.expectedEta = DateOnly.toJSON(message.expectedEta);
    }
    if (message.fulfillmentStatus !== undefined) {
      obj.fulfillmentStatus = resourceRequest_StatusToJSON(message.fulfillmentStatus);
    }
    return obj;
  },

  create(base?: DeepPartial<ResourceRequest>): ResourceRequest {
    return ResourceRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ResourceRequest>): ResourceRequest {
    const message = createBaseResourceRequest() as any;
    message.name = object.name ?? "";
    message.rrId = object.rrId ?? "";
    message.resourceDetails = object.resourceDetails ?? "";
    message.procurementEndDate = (object.procurementEndDate !== undefined && object.procurementEndDate !== null)
      ? DateOnly.fromPartial(object.procurementEndDate)
      : undefined;
    message.buildEndDate = (object.buildEndDate !== undefined && object.buildEndDate !== null)
      ? DateOnly.fromPartial(object.buildEndDate)
      : undefined;
    message.qaEndDate = (object.qaEndDate !== undefined && object.qaEndDate !== null)
      ? DateOnly.fromPartial(object.qaEndDate)
      : undefined;
    message.configEndDate = (object.configEndDate !== undefined && object.configEndDate !== null)
      ? DateOnly.fromPartial(object.configEndDate)
      : undefined;
    message.expectedEta = (object.expectedEta !== undefined && object.expectedEta !== null)
      ? DateOnly.fromPartial(object.expectedEta)
      : undefined;
    message.fulfillmentStatus = object.fulfillmentStatus ?? undefined;
    return message;
  },
};

export interface FleetConsole {
  /** Ping does not send or receive any information. It just checks that the service is there. */
  Ping(request: PingRequest): Promise<PingResponse>;
  /** PingBigQuery attempts to contact BigQuery service through Fleet Console. Useful for debugging. */
  PingBigQuery(request: PingBigQueryRequest): Promise<PingBigQueryResponse>;
  /** PingDeviceManager attempts to contact DeviceManager through Fleet Console. Useful for debugging. */
  PingDeviceManager(request: PingDeviceManagerRequest): Promise<PingDeviceManagerResponse>;
  /** PingUfs attempts to contact UFS through Fleet Console. Useful for debugging. */
  PingUfs(request: PingUfsRequest): Promise<PingUfsResponse>;
  /** ListDevices managed by Device Manager. */
  ListDevices(request: ListDevicesRequest): Promise<ListDevicesResponse>;
  /** GetDeviceDimensions provides overview of devices dimensions and their values */
  GetDeviceDimensions(request: Empty): Promise<GetDeviceDimensionsResponse>;
  /** CountDevices provides a count of the devices */
  CountDevices(request: CountDevicesRequest): Promise<CountDevicesResponse>;
  /** RepopulateCache repopulates the cache, meant to be triggered by cron. */
  RepopulateCache(request: RepopulateCacheRequest): Promise<RepopulateCacheResponse>;
  /** PingDB attempts to establish contact with the database and does nothing else. */
  PingDB(request: PingDBRequest): Promise<PingDBResponse>;
  /** CleanExit just exits the current process. */
  CleanExit(request: CleanExitRequest): Promise<CleanExitResponse>;
  /** ExportDevicesToCSV exports the devices in the csv format. */
  ExportDevicesToCSV(request: ExportDevicesToCSVRequest): Promise<ExportDevicesToCSVResponse>;
  /** ListResourceRequests returns Resource Requests provided by BigQuery */
  ListResourceRequests(request: ListResourceRequestsRequest): Promise<ListResourceRequestsResponse>;
}

export const FleetConsoleServiceName = "fleetconsole.FleetConsole";
export class FleetConsoleClientImpl implements FleetConsole {
  static readonly DEFAULT_SERVICE = FleetConsoleServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || FleetConsoleServiceName;
    this.rpc = rpc;
    this.Ping = this.Ping.bind(this);
    this.PingBigQuery = this.PingBigQuery.bind(this);
    this.PingDeviceManager = this.PingDeviceManager.bind(this);
    this.PingUfs = this.PingUfs.bind(this);
    this.ListDevices = this.ListDevices.bind(this);
    this.GetDeviceDimensions = this.GetDeviceDimensions.bind(this);
    this.CountDevices = this.CountDevices.bind(this);
    this.RepopulateCache = this.RepopulateCache.bind(this);
    this.PingDB = this.PingDB.bind(this);
    this.CleanExit = this.CleanExit.bind(this);
    this.ExportDevicesToCSV = this.ExportDevicesToCSV.bind(this);
    this.ListResourceRequests = this.ListResourceRequests.bind(this);
  }
  Ping(request: PingRequest): Promise<PingResponse> {
    const data = PingRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "Ping", data);
    return promise.then((data) => PingResponse.fromJSON(data));
  }

  PingBigQuery(request: PingBigQueryRequest): Promise<PingBigQueryResponse> {
    const data = PingBigQueryRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PingBigQuery", data);
    return promise.then((data) => PingBigQueryResponse.fromJSON(data));
  }

  PingDeviceManager(request: PingDeviceManagerRequest): Promise<PingDeviceManagerResponse> {
    const data = PingDeviceManagerRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PingDeviceManager", data);
    return promise.then((data) => PingDeviceManagerResponse.fromJSON(data));
  }

  PingUfs(request: PingUfsRequest): Promise<PingUfsResponse> {
    const data = PingUfsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PingUfs", data);
    return promise.then((data) => PingUfsResponse.fromJSON(data));
  }

  ListDevices(request: ListDevicesRequest): Promise<ListDevicesResponse> {
    const data = ListDevicesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListDevices", data);
    return promise.then((data) => ListDevicesResponse.fromJSON(data));
  }

  GetDeviceDimensions(request: Empty): Promise<GetDeviceDimensionsResponse> {
    const data = Empty.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDeviceDimensions", data);
    return promise.then((data) => GetDeviceDimensionsResponse.fromJSON(data));
  }

  CountDevices(request: CountDevicesRequest): Promise<CountDevicesResponse> {
    const data = CountDevicesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CountDevices", data);
    return promise.then((data) => CountDevicesResponse.fromJSON(data));
  }

  RepopulateCache(request: RepopulateCacheRequest): Promise<RepopulateCacheResponse> {
    const data = RepopulateCacheRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RepopulateCache", data);
    return promise.then((data) => RepopulateCacheResponse.fromJSON(data));
  }

  PingDB(request: PingDBRequest): Promise<PingDBResponse> {
    const data = PingDBRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "PingDB", data);
    return promise.then((data) => PingDBResponse.fromJSON(data));
  }

  CleanExit(request: CleanExitRequest): Promise<CleanExitResponse> {
    const data = CleanExitRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CleanExit", data);
    return promise.then((data) => CleanExitResponse.fromJSON(data));
  }

  ExportDevicesToCSV(request: ExportDevicesToCSVRequest): Promise<ExportDevicesToCSVResponse> {
    const data = ExportDevicesToCSVRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ExportDevicesToCSV", data);
    return promise.then((data) => ExportDevicesToCSVResponse.fromJSON(data));
  }

  ListResourceRequests(request: ListResourceRequestsRequest): Promise<ListResourceRequestsResponse> {
    const data = ListResourceRequestsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListResourceRequests", data);
    return promise.then((data) => ListResourceRequestsResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
